@use '../../styles/mixins.scss' as *;

.container {
  padding: 0 14px 14px 14px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.supportWrapper {
  margin-bottom: 28px;
}

.headWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.id {
  font-size: 9px;
  margin-bottom: 14px;
  color: var(--second-text-color);

  @include desktop {
    font-size: 14px;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fieldGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;

  @include tablet {
    width: 80%;
  }

  @include desktop {
    width: 80%;
  }
}

.errorText {
  color: var(--color-red);
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  width: 290px;
  line-height: 20.23px;
}

.transBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
}

.transText {
  text-align: center;
  margin-bottom: 14px;

  p {
    font-size: 14px;
    margin-bottom: 8px;
    color: var(--bold-text-color);
  }
}